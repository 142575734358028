export default {
  state: {
    created: 'Erstellt',
    snoozed: 'Zurückgestellt',
    active: 'Aktiv',
    completed: 'Abgeschlossen',
    skipped: 'Übersprungen',
    deleted: 'Abgebrochen'
  },
  dueDistance: 'Fällig {distance}',
  snoozeDistance: 'Zurückgestellt bis {distance}',
  todoCounts: {
    short: '{done} von {total}',
    long: '{done} von {total} Todos erledigt'
  },
  actions: {
    start: 'Aufgabe starten',
    complete: 'Aufgabe abschließen',
    reopen: 'Aufgabe wiedereröffnen',
    skip: 'Aufgabe überspringen',
    clone: 'Aufgabe duplizieren',
    move: 'Aufgabe verschieben',
    delete: 'Aufgabe löschen',
    addDataField: 'Datenfeld hinzufügen',
    editTitle: 'Titel bearbeiten',
    editDefinition: 'Vorlage bearbeiten'
  },
  listActions: {
    create: 'Starten',
    createFromDefinition: 'Aus Vorlage',
    createSingleTask: 'Einzelaufgabe'
  },
  noAssigneeDialog: {
    title: 'Verantwortliche:r werden?',
    text: 'Um diese Aktion durchzuführen, müssen Sie Verantwortliche:r sein.'
  },
  taskDataWithErrorsDialog: {
    title: 'Datenfelder mit Fehlerzustand',
    text: 'Mindestens ein Datenfeld befindet sich in einem Fehlerzustand. Bitte korrigieren Sie die fehlerhaften Eingaben.'
  },
  editTitleDialog: {
    title: 'Aufgabentitel bearbeiten',
    fields: {
      title: 'Titel der Aufgabe'
    }
  },
  deleteDialog: {
    title: 'Aufgabe löschen?',
    text: 'Löschen Sie die Aufgabe, wenn Sie sicher sind, dass diese nicht mehr benöigt wird. Gelöschte Aufgaben sind unwiederbringlich verloren.',
    buttons: {
      ok: 'Aufgabe löschen'
    }
  },
  cloneDialog: {
    title: 'Aufgabe duplizieren?',
    text: 'Es wird eine Kopie der Aufgabe {name} erzeugt. Eingaben und Verantwortlichkeiten werden nicht dupliziert, sondern zurückgesetzt.',
    buttons: {
      ok: 'Aufgabe duplizieren'
    }
  },
  moveDialog: {
    title: 'Aufgabe verschieben',
    fields: {
      targetProcess: 'Zielprozess',
      confirmationText: 'Nach dem Verschieben dieser letzten Aufgabe den Prozess löschen. Gelöschte Prozesse sind unwiederbringlich verloren.'
    },
    buttons: {
      ok: 'Aufgabe verschieben'
    }
  },
  addDialog: {
    fields: {
      title: 'Titel der Aufgabe',
      description: 'Beschreibung',
      assignee: 'Verantwortlich'
    }
  },
  addEditFieldDialog: {
    title: {
      add: 'Feld hinzufügen',
      edit: 'Feld bearbeiten'
    },
    buttons: {
      saveAndCreateAnother: 'Speichern und weiteres hinzufügen'
    },
    controls: {
      addNewFieldBtn: 'Neues Datenfeld erstellen',
      addExistingFieldBtn: 'Bestehendes Datenfeld nutzen',
      addNoteFieldBtn: 'Hinweisfeld erstellen',

      existingField: 'Bestehendes Datenfeld',
      showAsInfobox: 'Als nicht bearbeitbares Informationsfeld anzeigen',
      requiredToCompleteTask: 'Erforderlich zum Abschließen der Aufgabe',
      addToCalendar: 'Zum Kalender hinzufügen',
      noteValue: 'Hinweistext',
      defaultValue: {
        label: 'Vorgabewert',
        hint: 'Feld wird beim Start des Prozesses mit diesem Wert vorbelegt'
      },

      fieldName: 'Name des Datenfeldes',
      fieldType: 'Art des Datenfeldes'
    },
    successMessages: {
      dataField: 'Datenfeld "{title}" wurde erfolgreich hinzugefügt',
      noteField: 'Hinweisfeld wurde erfolgreich hinzugefügt'
    }
  },
  listItem: {
    hasShare: 'Zuarbeit aktiv'
  },
  widgets: {
    share: {
      title: 'Zuarbeit',
      actions: {
        create: 'Erstellen',
        complete: 'Abschliessen'
      },
      dialogs: {
        create: {
          title: 'Link für Zuarbeit erstellen',
          text: 'Du kannst diese Aufgabe für eine Zuarbeit durch Externe freigeben. Das bedeutet, dass die zuarbeitende Person alle Datenfelder dieser Aufgabe sieht und mit bearbeiten kann. Klicke auf „Zuarbeit erstellen" und teile den erstellten Link mit der zuarbeitenden Person. Für bessere Nachvollziehbarkeit kannst du den Namen der zuarbeitenden Person eintragen. Dieser wird nur intern genutzt.',
          fields: {
            name: {
              label: 'Zuarbeitende:r'
            }
          },
          buttons: {
            ok: 'Zuarbeit erstellen'
          }
        },
        complete: {
          title: 'Zuarbeit abschliessen',
          text: 'Wenn Sie die Zuarbeit abschliessen, können Zuarbeitende keine Daten mehr sehen oder ändern. Alle bisherigen Eingaben der Zuarbeitenden bleiben erhalten.',
          buttons: {
            ok: 'Zuarbeit abschliessen'
          }
        }
      },
      states: {
        active: 'Erstellt',
        completed: 'Abgeschlossen',
        canceled: 'Abgebrochen'
      },
      listItem: {
        recipient: 'Für {name}',
        noRecipient: 'unbenannt',
        when: 'am {date}'
      }
    }
  },
  editorHint: 'Benachrichtigt werden die hier aktiven Nutzer:innen sowie die jetzt von dir erwähnten Nutzer:innen und Gruppen. @:control.richtextarea.hint',
  mentioningHint: 'Diese Aufgabe mit {identifier} erwähnen.'
}
