<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import PageDetailHeaderTask from 'components/page-detail-header-task.vue'
import KebabMenu from 'components/kebab-menu.vue'
import EditTitleDialog from 'dialogs/edit-title-dialog.vue'
import ActivityHub from 'components/activity-hub/index.vue'
import MonitorSubscription from 'components/monitor-subscription.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import DataControl from 'controls/data-control'
import AddEditFieldDialog from 'dialogs/add-edit-field-dialog.vue'
import DeleteDataFieldDialog from 'dialogs/delete-data-field-dialog.vue'
import RequestDialog from 'dialogs/request-dialog.vue'
import MoveTaskToProcessDialog from 'dialogs/move-task-to-process-dialog.vue'
import DeleteTaskDialog from 'dialogs/delete-task-dialog.vue'
import Workflow from 'mixins/models/workflow'
import Task from 'mixins/models/task'
import ProcessDefinition from 'mixins/models/process-definition'
import DataFields from 'mixins/data-fields'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import draggable from 'vuedraggable'
import { routeFor } from 'helpers/route'
import globalIdFor from 'helpers/global-id'
import has from 'lodash/has'

export default {
  name: 'TaskEditContent',
  components: {
    PageDetailHeaderTask,
    KebabMenu,
    EditTitleDialog,
    ActivityHub,
    CustomDialog,
    DataControl,
    draggable,
    DeleteDataFieldDialog,
    RequestDialog,
    MoveTaskToProcessDialog,
    DeleteTaskDialog,
    AddEditFieldDialog,
    MonitorSubscription
  },
  mixins: [PageContentable, Task, Workflow, ProcessDefinition, DataFields],
  props: {
    designMode: {
      type: Boolean,
      required: true
    },
    contentItems: {
      type: Array,
      default: undefined
    }
  },
  data () {
    return {
      pageDataCount: 3,
      designRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: this.onDesignRequestSuccess
        }
      }),
      contentTypes: undefined,
      contentTypesRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.contentTypes = data
          }
        }
      })
    }
  },
  computed: {
    workflow () {
      return this.value ? this.value.workflow : null
    },
    processDefinition () {
      return this.workflow ? this.workflow.definition : null
    },
    task () {
      return this.value ? this.value.task : null
    },
    gid () {
      return globalIdFor('Task', this.pageProps.id)
    },
    gidProcess () {
      return globalIdFor('Workflow', this.workflow.id)
    }
  },
  watch: {
    task: {
      handler (value, oldValue) {
        if (this.task && (!oldValue || this.task.id !== oldValue.id || this.designMode)) {
          this.dataFieldsLoadFields(this.task.id)
        }
      },
      deep: true,
      immediate: true
    },
    workflow: {
      handler (value) {
        if (value && !this.contentItems) {
          this.$emit('component:event', 'load:content-items')
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.contentTypesRequestable.request(
      { method: Request.GET, url: this.$apiEndpoints.contentTypes.index() },
      null, null, true, true)
  },
  methods: {
    ...{ routeFor },
    onUpdated (workflow) {
      this.$emit('input', {
        workflow,
        task: this.workflowGetTaskWithIdFor(workflow, this.task.id)
      })
    },
    onUpdatedWithNextTask (workflow) {
      this.onUpdated(workflow)

      if (workflow.nextTaskId) {
        this.$router.push(routeFor('task', workflow.nextTaskId))
      }
    },
    onTaskMoved (workflow) {
      this.onUpdated(workflow)
      this.dataFieldsLoadFields(this.task.id)
    },
    onTaskDeleted (workflow) {
      this.$emit('input', {
        workflow,
        task: null
      })

      if (workflow.nextTaskId) {
        this.$router.push(routeFor('task', workflow.nextTaskId))
        this.$emit('component:event', 'load:content-items')
      } else {
        this.$router.replace(routeFor('tasks_by_definition', this.processDefinition.id))
      }
    },
    onProcessDeleted () {
      this.$router.replace(routeFor('tasks_by_definition', this.processDefinition.id))
    },
    onDesignRequestSuccess (data) {
      this.dataFieldsItems = data.dataFields
    },
    onDataFieldAddedOrDeleted (data) {
      this.dataFieldsLoadFieldsRequestable.cancelRequestable()
      this.dataFieldsItems = data.dataFields
      this.$emit('component:event', 'load:content-items')
    },
    onDataFieldEdited (data) {
      this.dataFieldsLoadFieldsRequestable.cancelRequestable()
      this.dataFieldsUpdateDataField(data.dataField)
      this.$emit('component:event', 'load:content-items')
    },
    onCommentsCountUpdated (count) {
      if (this.task) this.task.commentsCount = count
    },
    onTimeTrackingUpdated (timeInMinutes) {
      if (this.task && this.task.timeTracking) this.task.timeTracking.timeSpentInMinutes = timeInMinutes
    },
    onPositionChanged (event) {
      if (has(event, 'moved')) {
        this.designRequestable.request(
          { method: 'patch', url: this.$apiEndpoints.dataItems.move(event.moved.element.definition.task_item_id) },
          { index: event.moved.newIndex })
      }
    },
    dataFieldsOnFieldsLoaded () {
      this.pageDataLoaded()
    },
    dataFieldsOnDataFieldUpdated (dataField, data) {
      DataFields.methods.dataFieldsOnDataFieldUpdated.call(this, dataField, data)
      this.onUpdated(data.workflow)
    },
    execCompleteTaskRequest (actionRequest) {
      actionRequest(this.$apiEndpoints.tasks.complete(this.task.id), this.onUpdatedWithNextTask)
    },
    onMonitorMessage (data) {
      if (this.dataFieldsOnMonitorMessage(data, this.dataFieldsLoadField)) return

      switch (data.action) {
        case 'updated':
          this.$emit('fetchPageData')
          break
        case 'process_structure_updated':
          this.$emit('component:event', 'reload:process')
          break
      }
    }
  }
}
</script>
<template>
  <div :class="{ 'single-task': workflowIsSingleTask }">
    <template v-if="value && taskIsVariantFull">
      <monitor-subscription
        :key="`monitor-channel-${gid}`"
        :gid="gid"
        @received="onMonitorMessage"
      />

      <monitor-subscription
        :key="`monitor-process-structure-channel-${gidProcess}`"
        :gid="gidProcess"
        channel="MonitorProcessStructureChannel"
        @received="onMonitorMessage"
      />

      <custom-dialog
        v-model="dataFieldsErrorDialogOpen"
        :title="$t('task.taskDataWithErrorsDialog.title')"
        :text="$t('task.taskDataWithErrorsDialog.text')"
        :cancel-btn-text="null"
      />

      <page-detail-header-task
        :workflow="workflow"
        :task="task"
        :has-right-sidebar="hasRightSidebar"
        :has-left-sidebar="hasLeftSidebar"
        @open-sidebar="$emit('open-sidebar', $event)"
      >
        <template #actions="{actionRequest, loading}">
          <v-btn
            v-if="taskHasAvailableActionFor(task, 'start')"
            color="primary"
            text
            :disabled="loading"
            @click="dataFieldsCheckErrors() && actionRequest($apiEndpoints.tasks.start(task.id), onUpdated)"
          >
            {{ $t('task.actions.start') }}
          </v-btn>

          <v-btn
            v-else-if="taskHasAvailableActionFor(task, 'complete')"
            color="primary"
            text
            :disabled="loading || !!dataFieldsDeferredFunc"
            @click="dataFieldsExecFuncWithDataErrorCheck(() => execCompleteTaskRequest(actionRequest))"
          >
            {{ $t('task.actions.complete') }}
          </v-btn>

          <v-btn
            v-else-if="taskHasAvailableActionFor(task, 'reopen')"
            color="primary"
            text
            :disabled="loading"
            @click="actionRequest($apiEndpoints.tasks.reopen(task.id), onUpdated)"
          >
            {{ $t('task.actions.reopen') }}
          </v-btn>

          <kebab-menu
            :disabled="loading"
            color="primary"
          >
            <template #items="{ closeMenu }">
              <edit-title-dialog
                :request-parameter="{method: 'patch', url: $apiEndpoints.tasks.update(task.id), mapping: (data) => { return {'name': data.title, 'lock_version': task.lock_version }}}"
                :title-value="task.name"
                attribute="name"
                required
                :title="$t('task.editTitleDialog.title')"
                :label="$t('task.editTitleDialog.fields.title')"
                @request-success-data="onUpdated"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    {{ $t('task.actions.editTitle') }}
                  </v-list-item>
                </template>
              </edit-title-dialog>

              <v-list-item
                v-if="taskHasAvailableActionFor(task, 'start') && taskHasAvailableActionFor(task, 'complete')"
                @click="dataFieldsExecFuncWithDataErrorCheck(() => execCompleteTaskRequest(actionRequest))"
              >
                {{ $t('task.actions.complete') }}
              </v-list-item>

              <v-list-item
                v-if="taskHasAvailableActionFor(task, 'skip')"
                @click="dataFieldsCheckErrors() && actionRequest($apiEndpoints.tasks.skip(task.id), onUpdatedWithNextTask)"
              >
                {{ $t('task.actions.skip') }}
              </v-list-item>

              <request-dialog
                :title="$t('task.cloneDialog.title')"
                :ok-btn-text="$t('task.cloneDialog.buttons.ok')"
                :request-parameter="{method: 'patch', url: $apiEndpoints.tasks.clone(task.id)}"
                @request-success="onUpdated"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    {{ $t('task.actions.clone') }}
                  </v-list-item>
                </template>
                <i18n path="task.cloneDialog.text">
                  <template #name>
                    <b>"{{ task.referenceLabel }}"</b>
                  </template>
                </i18n>
              </request-dialog>

              <move-task-to-process-dialog
                :task-id="task.id"
                :process-id="workflow.id"
                @request-success-data="onTaskMoved"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    {{ $t('task.actions.move') }}
                  </v-list-item>
                </template>
              </move-task-to-process-dialog>

              <delete-task-dialog
                :request-parameter="{ method: 'delete', url: workflowIsSingleTask ? $apiEndpoints.workflows.destroy(workflow.id) : $apiEndpoints.tasks.destroy(task.id)}"
                @request-success-data="workflowIsSingleTask ? onProcessDeleted() : onTaskDeleted($event)"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item
                    :disabled="!workflowIsSingleTask && (workflowGetTasksFor(workflow).length === 1)"
                    v-on="on"
                  >
                    {{ $t('task.actions.delete') }}
                  </v-list-item>
                </template>
              </delete-task-dialog>

              <v-list-item
                v-if="!isSystemProcessDefinitionSingleTask && task.definitionId !== null"
                :to="routeFor('task_definition', processDefinition.id, { params: { taskDefinitionId: task.definitionId } })"
              >
                <v-list-item-title>{{ $t('task.actions.editDefinition') }}</v-list-item-title>
              </v-list-item>
            </template>
          </kebab-menu>
        </template>
      </page-detail-header-task>

      <template v-if="dataFieldsLoadFieldsRequestable.requestableLoading">
        <v-alert
          dense
          text
          type="info"
        >
          <template #prepend>
            <v-progress-circular
              color="primary"
              indeterminate
              size="20"
              width="2"
              class="mr-2"
            />
          </template>
          Daten werden geladen
        </v-alert>
      </template>
      <template v-else-if="dataFieldsItems">
        <draggable
          :list="dataFieldsItems"
          handle=".handle"
          :force-fallback="true"
          :animation="200"
          @change="onPositionChanged($event)"
        >
          <div
            v-for="(dataField, index) in dataFieldsItems"
            :key="dataField.definition.task_item_id"
            :class="{'d-flex align-center': designMode}"
          >
            <v-icon
              v-if="designMode"
              :disabled="designRequestable.requestableLoading"
              color="purple lighten-2"
              class="handle mr-2"
            >
              mdi-drag-horizontal
            </v-icon>

            <add-edit-field-dialog
              v-if="designMode"
              :value="{...dataField.definition, value: dataField.value}"
              :content-items="contentItems"
              :content-types="contentTypes"
              :request-parameter="{method: 'patch', url: $apiEndpoints.dataItems.update(dataField.definition.task_item_id)}"
              @request-success-data="onDataFieldEdited"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  :disabled="designRequestable.requestableLoading"
                  class="mr-2"
                  v-on="on"
                >
                  <v-icon color="purple lighten-2">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </add-edit-field-dialog>

            <delete-data-field-dialog
              v-if="designMode"
              :task-usage-count="dataField.definition.task_item_count"
              :block-usage-count="dataField.definition.block_count"
              :request-parameter="{ method: 'delete', url: $apiEndpoints.dataItems.destroy(dataField.definition.task_item_id) }"
              @request-success-data="onDataFieldAddedOrDeleted"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  :disabled="designRequestable.requestableLoading"
                  class="mr-2"
                  v-on="on"
                >
                  <v-icon color="purple lighten-2">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </delete-data-field-dialog>

            <data-control
              :ref="dataFieldsControlRef(dataField)"
              :key="'control_' + dataField.definition.task_item_id"
              v-model="dataField.value"
              :acl-object-global-id="task.gid"
              :type="dataField.definition.type"
              :label="dataField.definition.name"
              :options="dataField.definition.options"
              :required="dataField.definition.required"
              :infobox="dataField.definition.infobox"
              :confirmed-at="dataField.confirmedAt"
              :last-updated="dataField.lastUpdated"
              :locked-by="dataField.lockedBy"
              :disabled="task.dataReadonly"
              :request-parameter="{method: 'patch', url: $apiEndpoints.dataItems.update(dataField.definition.task_item_id), mapping: (data) => { return {'value': data, 'lock_version': dataField.lock_version}}}"
              :lock-request-parameter="{method: 'patch', url: $apiEndpoints.dataItems.lock(dataField.definition.task_item_id), mapping: (data) => { return {'lock_version': dataField.lock_version}}}"
              :unlock-request-parameter="{method: 'patch', url: $apiEndpoints.dataItems.unlock(dataField.definition.task_item_id)}"
              :class="{'mb-5': index < dataFieldsItems.length - 1, 'flex-grow-1': designMode}"
              @request-success-data="dataFieldsOnDataFieldUpdated(dataField, $event)"
              @before-lock="dataFieldsOnDataFieldBeforeLock(dataField)"
              @lock="dataFieldsOnDataFieldLocked(dataField, $event)"
              @unlock="dataFieldsOnDataFieldUnlocked(dataField, $event)"
            />
          </div>
        </draggable>
      </template>

      <div
        v-if="designMode && workflowHasAvailableActionFor(workflow, 'design')"
        class="d-flex justify-center mt-2"
      >
        <add-edit-field-dialog
          :request-parameter="{method: 'post', url: $apiEndpoints.dataItems.create(), params: { task_id: task && task.id} }"
          :content-items="contentItems"
          :content-types="contentTypes"
          @request-success-data="onDataFieldAddedOrDeleted"
        >
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              color="purple lighten-2"
              v-on="on"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
        </add-edit-field-dialog>
      </div>
    </template>

    <activity-hub
      v-if="gid"
      ref="activityhub"
      :key="`activity-hub-${gid}`"
      :gid="gid"
      :groups="(workflow || {}).groups"
      :editor-hint="$t('task.editorHint')"
      class="mt-12"
      time-tracking
      @comments-count-updated="onCommentsCountUpdated"
      @time-tracking-updated="onTimeTrackingUpdated"
      @loaded="pageDataLoaded"
    />
  </div>
</template>
<style>
.sortable-drag {
  background-color: #F3E5F5;
}
</style>
