<script>
import Requestable from 'mixins/requestable'
import Request from 'api/request'

export default {
  name: 'UserInfo',
  mixins: [Requestable],
  data () {
    return {
      countUndoneNotifications: '?',
      hasUnreadNotifications: false,
      countOpenUserAssignedTasks: '?',
      hasDueOpenUserAssignedTasks: false
    }
  },
  channels: {
    UserChannel: {
      connected () { this.$debugLog('connected to UserChannel via vue') },
      rejected () {},
      received (data) {
        this.setData(data)
      },
      disconnected () {}
    }
  },
  computed: {
    userAssignedOpenTaskCountBadgeColor () {
      return this.hasDueOpenUserAssignedTasks ? 'error' : 'primary'
    }
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'UserChannel'
    })
  },
  created () {
    this.request({ method: Request.GET, url: this.$apiEndpoints.users.info() },
      null, null, true)
  },
  methods: {
    setData (data = undefined) {
      this.countUndoneNotifications = data ? data.count_undone_notifications : '?'
      this.hasUnreadNotifications = data ? data.has_unread_notifications : false
      this.countOpenUserAssignedTasks = data ? data.count_open_user_assigned_tasks : '?'
      this.hasDueOpenUserAssignedTasks = data ? data.has_due_open_user_assigned_tasks : false
    },
    onRequestSuccess (data) {
      this.setData(data)
    },
    onRequestError (error) {
      this.setData()
      console.log('get data error:')
      console.log(error)
    }
  }
}
</script>
<template>
  <div class="d-flex">
    <v-btn
      id="user-notifications-button"
      :to="{ name: 'notifications' }"
      class="user-info-notifications-btn mr-4"
      icon
      :title="$tc('siteHeader.userNotifications', countUndoneNotifications)"
    >
      <v-badge
        v-if="hasUnreadNotifications || countUndoneNotifications > 0"
        :color="hasUnreadNotifications ? '#ee8450' : 'primary'"

        offset-x="5"
        offset-y="10"
      >
        <template
          v-if="hasUnreadNotifications"
          #badge
        >
          <span>{{ countUndoneNotifications }}</span>
        </template>
        <v-icon>mdi-bell</v-icon>
      </v-badge>
      <v-icon v-else>
        mdi-bell
      </v-icon>
    </v-btn>

    <v-btn
      id="user-tasks-button"
      icon
      class="user-info-tasks-btn"
      :to="{ name: 'tasks', query: { assignee_ids: [$config.current_user.id], order: 'by_due_date' } }"
      exact
    >
      <v-badge
        v-if="countOpenUserAssignedTasks > 0"
        :color="userAssignedOpenTaskCountBadgeColor"
        offset-x="5"
        offset-y="10"
      >
        <template #badge>
          <span>{{ countOpenUserAssignedTasks }}</span>
        </template>
        <v-icon>mdi-account</v-icon>
      </v-badge>
      <v-icon v-else>
        mdi-account
      </v-icon>
    </v-btn>
  </div>
</template>
<style lang="scss">
  .v-btn.user-info-notifications-btn .v-badge.v-badge_small .v-badge__badge {
    border-radius: 7.5px;
    height: 15px;
    min-width: 0;
    padding: 0;
    margin-left: 2px;
    margin-bottom: 2px;
    width: 15px;

    & > span {
      display: none
    }
  }
</style>
