export default {
  fields: {
    title: 'Titel der Prozessvorlage',
    description: 'Beschreibung',
    startDialogFields: 'Felder im Startdialog',
    autoGenerateTitle: 'Titel automatisch aus Feldinhalten generieren',
    autoGenerateTitleFields: 'Datenfelder für den Titel',
    groups: 'Gruppen',
    taskDefinitions: {
      title: 'Titel der Aufgabe',
      candidateAssignee: 'Automatisch Verantwortlich',
      candidateContributors: 'Automatisch Teilnehmend',
      automaticDueInDays: 'Automatisch fällig nach Start der Aufgabe (in Tagen)',
      automaticDeferralInDays: 'Automatisch Zurückstellen nach Start der Aufgabe (in Tagen)',
      fields: 'Felder'
    }
  },
  actions: {
    create: 'Prozessvorlage erstellen',
    delete: 'Prozessvorlage löschen'
  },
  detailActions: {
    deactivate: 'Prozessvorlage deaktivieren',
    activate: 'Prozessvorlage aktivieren'
  },
  dialogs: {
    createProcess: {
      title: 'Prozessvorlage erstellen',
      successMessage: 'Prozessvorlage "{title}" wurde erfolgreich erstellt'
    },
    deleteProcess: {
      title: 'Prozessvorlage löschen?',
      text: 'Löschen Sie die Prozessvorlage, wenn Sie sicher sind, dass diese Prozessvorlage nicht mehr benöigt wird. Gelöschte Prozessvorlagen sind unwiederbringlich verloren.'
    },
    deleteTask: {
      title: 'Aufgabe löschen?',
      text: 'Löschen Sie die Aufgabe, wenn Sie sicher sind, dass diese Aufgabe nicht mehr benöigt wird. Gelöschte Aufgaben sind unwiederbringlich verloren.'
    },
    deleteBlock: {
      title: 'Block löschen?',
      text: 'Löschen Sie den Block, wenn Sie sicher sind, dass dieser Block nicht mehr benöigt wird. Gelöschte Blöcke sind unwiederbringlich verloren.'
    },
    cloneTask: {
      title: 'Aufgabe duplizieren?',
      text: 'Es wird eine Kopie der Aufgabe {name} erzeugt. Alle Konfigurationen und Datenfelder werden dabei dupliziert.',
      buttons: {
        ok: 'Aufgabe duplizieren'
      }
    }
  },
  editorHint: 'Benachrichtigt werden die hier aktiven Nutzer:innen sowie die jetzt von dir erwähnten Nutzer:innen und Gruppen. @:control.richtextarea.hint',
  titleFieldsSelectionHint: '@:general.sortableHint Wenn keine weiteren Felder ausgewählt werden wird als Titel des Prozesses "Identifier + Name Definition" gesetzt.',
  hiddenElements: {
    singular: 'Es existiert {count} aktive Prozessvorlage, welche keiner Gruppe zugeordnet ist. Diese Vorlage und die dazugehörigen Instanzen sind für niemanden sichtbar.',
    plural: 'Es existieren {count} aktive Prozessvorlagen, welche keiner Gruppe zugeordnet sind. Diese Vorlagen und die dazugehörigen Instanzen sind für niemanden sichtbar.',
    message: '| @:processDefinition.hiddenElements.singular | @:processDefinition.hiddenElements.plural'
  },
  hiddenMessage: 'Diese Prozessvorlage ist keiner Gruppe zugeordnet. Die Vorlage und alle Instanzen sind für niemanden sichtbar.'
}
